/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import illustrationUrl from "../../../assets/images/illustration.svg";
import Alert from "../../../base-components/Alert";
import Button from "../../../base-components/Button";
import { FormCheck, FormInput } from "../../../base-components/Form";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { selectAccessToken, setUser } from "../../../stores/userSlice";
import logoUrl from "/finance.png";

function Main() {
	const nav = useNavigate()
	const accessToken = useAppSelector(selectAccessToken);


	const { showLoader, hideLoader } = useLoader();
	const dispatch = useAppDispatch();


	const userRef = useRef()
	const [username, setUsername] = useState('')
	const [passwd, setPasswd] = useState('')
	const [isError, setIsError] = useState(false)
	const [errMsg, setErrMsg] = useState('')

	useEffect(() => {
		if (accessToken) nav('/finance')
		else if (userRef.current) (userRef.current as any).focus()
	}, [])

	const handleSubmit = async (e) => {
		try {//credentials: 'include','Authorization': bearer,
			e.preventDefault()
			console.log('login...')
			showLoader()
			const body = { email: username, password: passwd, twoFactorCode: '', twoFactorRecoveryCode: '' }
			const f = await fetch('/login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
			if (f.ok) {
				const r = await f.json()
				if (r.status == 401 || r == undefined) {
					setErrMsg(r.title + ' ' + r.detail)
					setIsError(true)
					setTimeout(() => { setIsError(false) }, 5000)
				} else {
					setIsError(false)
					setUsername('')
					setPasswd('')
					dispatch(setUser(r))
					nav('/finance')
				}
			} else console.error(f.status, f.statusText)
		} catch (e) {
			setErrMsg('Exception: ' + e)
			setIsError(true)
			console.error(e)
		}
		finally { hideLoader() }
	}

	return <>
		<div
			className={clsx([
				"-m-3 sm:-mx-8 p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600",
				"before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400",
				"after:hidden after:xl:block after:content-[''] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700",
			])}>
			{/*<DarkModeSwitcher />*/}
			{/*<MainColorSwitcher />*/}
			<div className="container relative z-10 sm:px-10">
				<div className="block grid-cols-2 gap-4 xl:grid">
					<div className="flex-col hidden min-h-screen xl:flex">
						<NavLink to="/" className="flex items-center pt-5 -intro-x">
							<img alt="UKS Logo" className="w-6" src={logoUrl} />
							<span className="ml-3 text-lg text-white">UKS</span>
						</NavLink>
						<div className="my-auto">
							<img alt="UKS Illustration" className="w-1/2 -mt-16 -intro-x" src={illustrationUrl} />
							<div className="mt-10 text-4xl font-medium leading-tight text-white -intro-x">
								A few more clicks to <br />
								sign in to your account.
							</div>
							<div className="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400">
								Manage all your finance in one place
							</div>
						</div>
					</div>
					<div className="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
						<div className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
							<h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">Sign In</h2>
							<div className="mt-2 text-center intro-x text-slate-400 xl:hidden">
								A few more clicks to sign in to your account. Manage all your finance in one place
							</div>
							<div className="mt-8 intro-x">
								{isError && <Alert variant="outline-danger" className="flex items-center mb-2">
									{({ dismiss }) => (
										<>
											<Lucide icon="AlertOctagon" className="w-6 h-6 mr-2" />{" "}
											Invalid username or password<br />{errMsg}
											<Alert.DismissButton type="button" className="btn-close" onClick={dismiss} aria-label="Close">
												<Lucide icon="X" className="w-4 h-4" />
											</Alert.DismissButton>
										</>
									)}
								</Alert>}
								<FormInput type="text" className="block px-4 py-3 intro-x min-w-full xl:min-w-[350px]"
									placeholder="Email" onChange={(e) => setUsername(e.target.value)} />
								<FormInput type="password" className="block px-4 py-3 mt-4 intro-x min-w-full xl:min-w-[350px]"
									placeholder="Password" onChange={(e) => setPasswd(e.target.value)} />
							</div>
							<div className="flex mt-4 text-xs intro-x text-slate-600 dark:text-slate-500 sm:text-sm">
								<div className="flex items-center mr-auto">
									<FormCheck.Input id="remember-me" type="checkbox" className="mr-2 border" />
									<label className="cursor-pointer select-none" htmlFor="remember-me" >Remember me</label>
								</div>
								<NavLink to="/">Forgot Password?</NavLink>
							</div>
							<div className="flex mt-5 text-center intro-x xl:mt-8 xl:text-left">
								<Button variant="primary" className="mx-auto px-4 py-3 align-top xl:w-32" onClick={(e) => handleSubmit(e)}>Login</Button>
								{/*<Button variant="outline-secondary" className="w-full px-4 py-3 mt-3 align-top xl:w-32 xl:mt-0"*/}
								{/*	onClick={handleSubmit} > Register </Button>*/}
							</div>
							<div className="mt-10 text-center intro-x xl:mt-24 text-slate-600 dark:text-slate-500 xl:text-left">
								By signin up, you agree to our{" "}
								<NavLink className="text-primary dark:text-slate-200" to="/">Terms and Conditions</NavLink>{" & "}
								<NavLink className="text-primary dark:text-slate-200" to="/">Privacy Policy</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default Main